import React from 'react'
import styled from 'styled-components'

import Container from './Container'

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  height: 79px;
  background: #000;
  color: #fff;
  text-align: center;
`

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <p>
          Copyright © 2020 Official Black Wall Street | All Rights Reserved
          Terms & Conditions
        </p>
      </Container>
    </StyledFooter>
  )
}

export default Footer
