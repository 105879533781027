import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'

//Custom Imports
import Card from './Card'

const StyledCategory = styled.div`
  /* display: flex; */
  padding: 2rem 4rem;
  text-align: center;
  background-image: linear-gradient(to right, #33d2c4, #cbe81b, #6ad504);
`

const StyledCategoryList = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 28px;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    row-gap: 28px;
  }
`

const StyledCategoryNav = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 3rem;

  a {
    cursor: pointer;
    color: #000;
  }

  button {
    margin: 0;
    padding: 1rem 1.5rem;
    background: #f15a24;
    border: none;
    font-size: 1rem;
    color: #fff;
    border-radius: 40px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
    a {
      display: block;
      cursor: pointer;
    }
  }
`

const Category = ({
  currentCategory,
  onNextClick,
  onPrevClick,
  setUpdateVote,
  categoriesLength,
  submitVotes,
  voted
}) => {
  return (
    <StyledCategory>
      <StyledCategoryList>
        {currentCategory.category_options.map(option => {
          return (
            <Card
              key={option.id}
              option={option}
              voted={currentCategory.vote === option.id}
              updateVote={setUpdateVote}
            />
          )
        })}
      </StyledCategoryList>
      <StyledCategoryNav>
        {currentCategory.id > 1 && (
          <a href='#!' onClick={onPrevClick}>
            {' '}
            <FontAwesomeIcon icon={faChevronLeft} /> Previous Category{' '}
          </a>
        )}
        |
        {currentCategory.id < categoriesLength && (
          <a href='#!' onClick={onNextClick}>
            {' '}
            Next Category <FontAwesomeIcon icon={faChevronRight} />
          </a>
        )}
        <br />
        <br />
        <p>Voting Ended. Thanks for voting</p>
      </StyledCategoryNav>
    </StyledCategory>
  )
}

export default Category
