import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const StyledCard = styled.div`
  text-align: center;
  min-height: 220px;
  max-height: fit-content;
  background: ${props => (props.voted ? '#000' : '#fff')};
  box-shadow: 7px 7px 10px #00000029;
  border-radius: 14px;
  position: relative;
  flex-grow: 1;
  padding-bottom: 1rem;
`

const StyledCardDetails = styled.div`
  position: relative;
  top: -10px;
  width: 80%;
  margin: 0 auto;

  img {
    width: 60%;
    height: 60%;
    object-fit: contain;
    border-radius: 100%;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 25;
    color: ${props => (props.voted ? '#fff' : '#000')};
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 200;
    color: ${props => (props.voted ? '#fff' : '#000')};
  }
`

const StyledCardAction = styled.div`
  text-transform: uppercase;
  margin-top: 1rem;

  a {
    cursor: pointer;
    color: #000;
  }
`

const StyledCardVoted = styled.div`
  text-transform: uppercase;
  color: #a4e11e;

  span {
    display: block;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
`

const Card = ({ option, voted, updateVote }) => {
  const { Name, Company, id, imagelink } = option

  return (
    <StyledCard voted={voted}>
      <StyledCardDetails voted={voted}>
        <img src={imagelink} alt='' />
        <h3>{Name}</h3>
        <p>{Company}</p>
      </StyledCardDetails>

      {voted ? (
        <StyledCardVoted>
          <h3>
            You voted <span>for this business</span>
          </h3>
        </StyledCardVoted>
      ) : (
        <StyledCardAction>
          <a href='#!' onClick={() => updateVote(id)}>
            Vote Now <br /> <FontAwesomeIcon icon={faChevronDown} />
          </a>
        </StyledCardAction>
      )}
    </StyledCard>
  )
}

export default Card
