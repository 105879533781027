import React from 'react'
import styled from 'styled-components'
import Container from './Container'
import logo2 from '../assets/logo2.png'

const StyledHeader = styled.div`
  box-shadow: 6px 6px 10px #00000029;
  padding: 5rem 0;
`

const StyledHeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  img {
    width: 40%;
  }

  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    text-align: right;
    line-height: 0.2;

    h1 {
      margin: 1rem 0;
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    justify-content: center;

    img {
      width: 100%;
      margin-bottom: 3rem;
    }

    div {
      text-align: center;
      line-height: 1;

      h1 {
        margin-top: 0rem;
      }
    }
  }
`

const Header = ({ title, subTitle }) => {
  return (
    <StyledHeader>
      <Container>
        <StyledHeaderInner>
          <img src={logo2} alt='Logo2' />
          <div>
            <h1>{title}</h1>
            <h1>{subTitle}</h1>
            <p>Vote for your favorite!</p>
          </div>
        </StyledHeaderInner>
      </Container>
    </StyledHeader>
  )
}

export default Header
