import React, { Fragment, useState, useEffect } from 'react'
import Loader from 'react-loader-spinner'
import ReactGA from 'react-ga'
import axios from 'axios'
import dotenv from 'dotenv'

import './App.css'

// Custom Imports
import Footer from './components/Footer'
import Header from './components/Header'
import Navbar from './components/Navbar'
import Category from './components/Category'
import Container from './components/Container'
import styled from 'styled-components'

dotenv.config()
ReactGA.initialize('UA-63840607-6')
ReactGA.pageview(window.location.pathname + window.location.search)

function App() {
  const [categories, setCategories] = useState(null)
  const [currentCat, setCurrentCat] = useState({})
  const [currentCatIndex, setCurrentCatIndex] = useState(null)
  const [currentUser, setCurrentUser] = useState('')

  const StyledContent = styled.section`
    min-height: 80vh;
  `

  const fetchCategories = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_API_URL}/categories`)
      .then(res => {
        setCategories(prevState => {
          let updatedCategories = res.data
          updatedCategories = updatedCategories.map(category => {
            category.vote = null
            return category
          })
          setCurrentCat(updatedCategories[0])
          setCurrentCatIndex(0)
          return updatedCategories
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getClientIP = async () => {
    let IP
    try {
      let res = await axios.get('https://geolocation-db.com/json/')
      IP = res.data.IPv4

      let newUser = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/voters`,
        {
          IP_Address: IP
        }
      )

      setCurrentUser(newUser.data)
    } catch (error) {
      // if (error.message.includes('500')) {
      let newUser = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/voters/ip/${IP}`
      )
      setCurrentUser(newUser.data)
      // }
      console.log(error)
    }
  }

  const vote = async id => {
    await axios.post(`${process.env.REACT_APP_BASE_API_URL}/votes`, {
      category_option: id
    })

    // console.log(res)
  }

  const updateUserVoted = async () => {
    let res = await axios.put(
      `${process.env.REACT_APP_BASE_API_URL}/voters/${currentUser.id}`,
      {
        voted: true
      }
    )

    setCurrentUser(res.data)
  }

  useEffect(() => {
    // console.log(process.env.REACT_APP_BASE_API_URL)
    fetchCategories()
    getClientIP()
  }, [])

  const onNextClickHandler = () => {
    setCurrentCatIndex(prevState => {
      if (prevState < categories.length - 1) {
        let nextState = prevState + 1
        setCurrentCat(categories[nextState])
        return nextState
      }

      return prevState
    })
  }

  const onPrevClickHandler = () => {
    setCurrentCatIndex(prevState => {
      if (prevState > 0) {
        let nextState = prevState - 1
        setCurrentCat(categories[nextState])
        return nextState
      }

      return prevState
    })
  }

  const setVoteHandler = id => {
    setCategories(prevState => {
      let newState = prevState.map(cat => {
        if (cat.id === currentCatIndex + 1 && cat.vote === null) {
          cat.vote = id
        }
        return cat
      })
      return newState
    })
  }

  const onSubmitVotesHandler = () => {
    // Submit Votes
    categories.forEach(category => {
      if (category.vote) {
        vote(category.vote)
      }
    })

    // Set user as voted
    updateUserVoted()
  }

  let content

  if (categories === null) {
    content = (
      <Container>
        <div style={{ textAlign: 'center', padding: '1rem 0' }}>
          <Loader
            type='Oval'
            color='#F15A24'
            secondaryColor='#F15A24'
            height={400}
          />
        </div>
      </Container>
    )
  } else {
    content = (
      <Fragment>
        <Header title={currentCat.Title} subTitle={currentCat.Sub_Title} />
        <Category
          currentCategory={currentCat}
          onNextClick={onNextClickHandler}
          onPrevClick={onPrevClickHandler}
          setUpdateVote={setVoteHandler}
          categoriesLength={categories.length}
          submitVotes={onSubmitVotesHandler}
          voted={currentUser.voted}
        />
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Navbar />
      <StyledContent>{content}</StyledContent>
      <Footer />
    </Fragment>
  )
}

export default App
